<template>
  <div>
    <el-table
      ref="tableListRef"
      v-table-skeleton="{ loading: props.tableLoading, rows: 17 }"
      :data="props.tableData.list"
      border
      stripe
      :height="props.tableHeight"
      class="elv-valuation-record-table"
      header-cell-class-name="elv-valuation-record-table-header__cell"
      header-row-class-name="elv-valuation-record-table-header"
      row-class-name="elv-valuation-record-table-row"
      cell-class-name="elv-valuation-record-table-row__cell"
    >
      <el-table-column type="index" width="40" align="center" class-name="elv-valuation-record-table-row__cell-index">
        <template #default="{ row }">
          <span>{{ (row.rowIndex + 1) * props.params.page }}</span>
        </template>
      </el-table-column>
      <el-table-column :label="t('valuation.crypto.adjustingId')" width="110">
        <template #default="{ row }">
          <TextCell :text="row.adjustingNo" :weight="500" />
        </template>
      </el-table-column>
      <el-table-column :label="t('common.type')" width="100">
        <template #default="{ row }">
          <TextCell :text="getTypeName(row.type) ?? ''" font-family="Plus Jakarta Sans" />
        </template>
      </el-table-column>
      <el-table-column :label="t('common.date')" width="125">
        <template #default="{ row }">
          <TextCell :text="dayjs(row.date)?.format('YYYY/MM/DD')" />
        </template>
      </el-table-column>
      <el-table-column :label="t('common.currency')" width="150">
        <template #default="{ row }">
          <IconWithTextCell
            :icon="row.underlyingCurrency?.logo"
            :title="row.underlyingCurrency?.showSymbol"
            titleFontFamily="Barlow"
            :titleWeight="400"
          />
        </template>
      </el-table-column>
      <el-table-column
        :label="t('valuation.crypto.chartOfAccount')"
        width="130"
        show-overflow-tooltip
        class-name="elv-valuation-record-table-row__cell-referenceNo"
      >
        <template #default="{ row }">
          <TextCell :text="row.chartOfAccount?.name" />
        </template>
      </el-table-column>
      <el-table-column :label="t('report.auxiliaryCode')" width="110">
        <template #default="{ row }">
          <TextCell :text="getAuxiliaryCodes(row)" />
        </template>
      </el-table-column>
      <el-table-column :label="t('valuation.crypto.opening')" width="110">
        <template #default="{ row }">
          <VerticalTextCell
            :upText="
              formatNumberToSignificantDigits(
                row.openingFC,
                2,
                `${entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `}`
              )
            "
            :upDesc="
              fieldValueFormat(
                row.openingFC,
                {
                  price: true,
                  keepPoint: true,
                  symbol: `${
                    entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `
                  }`
                },
                'NUMBER'
              )
            "
            :upWeight="500"
            upFontSize="12px"
            :downText="`${formatNumberToSignificantDigits(row.openingQuantity, 2, '', false)} ${
              row.underlyingCurrency?.showSymbol
            }`"
            :downDesc="`${formatNumber(row.openingQuantity, 20)} ${row.underlyingCurrency?.showSymbol}`"
            align="left"
          />
        </template>
      </el-table-column>

      <el-table-column :label="t('common.debit')" width="110">
        <template #default="{ row }">
          <VerticalTextCell
            :upText="
              formatNumberToSignificantDigits(
                row.debitFC,
                2,
                `${entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `}`
              )
            "
            :upWeight="500"
            :upSesc="
              fieldValueFormat(
                row.debitFC,
                {
                  price: true,
                  keepPoint: true,
                  symbol: `${
                    entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `
                  }`
                },
                'NUMBER'
              )
            "
            upFontSize="12px"
            :downText="`${row.debit} ${row?.underlyingCurrency?.showSymbol}`"
            :downDesc="`${formatNumber(row.debit, 20)} ${row?.underlyingCurrency?.showSymbol}`"
            align="left"
          />
        </template>
      </el-table-column>
      <el-table-column :label="t('common.credit')" width="110">
        <template #default="{ row }">
          <VerticalTextCell
            :upText="
              formatNumberToSignificantDigits(
                row.creditFC,
                2,
                `${entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `}`
              )
            "
            :upDesc="
              fieldValueFormat(
                row.creditFC,
                {
                  price: true,
                  keepPoint: true,
                  symbol: `${
                    entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `
                  }`
                },
                'NUMBER'
              )
            "
            :upWeight="500"
            upFontSize="12px"
            :downText="`${row.credit} ${row?.underlyingCurrency?.showSymbol}`"
            :downDesc="`${formatNumber(row.credit, 20)} ${row?.underlyingCurrency?.showSymbol}`"
            align="left"
          />
        </template>
      </el-table-column>
      <el-table-column :label="t('valuation.crypto.closing')" width="110">
        <template #default="{ row }">
          <VerticalTextCell
            :upText="
              formatNumberToSignificantDigits(
                row.closingFC,
                2,
                `${entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `}`
              )
            "
            :upDesc="
              fieldValueFormat(
                row.closingFC,
                {
                  price: true,
                  keepPoint: true,
                  symbol: `${
                    entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `
                  }`
                },
                'NUMBER'
              )
            "
            :upWeight="500"
            upFontSize="12px"
            :downText="`${formatNumberToSignificantDigits(row.closingQuantity, 2, '', false)} ${
              row.underlyingCurrency?.showSymbol
            }`"
            :downDesc="`${formatNumber(row.closingQuantity, 20)} ${row?.underlyingCurrency?.showSymbol}`"
            align="left"
          />
        </template>
      </el-table-column>
      <el-table-column :label="t('valuation.crypto.closingPrice')" width="120">
        <template #default="{ row }">
          <TextWithIconCell
            :text="
              formatNumberToSignificantDigits(
                row.closingPrice,
                2,
                `${entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `}`
              )
            "
            :titleDesc="
              fieldValueFormat(
                row.closingPrice,
                {
                  price: true,
                  keepPoint: true,
                  symbol: `${
                    entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `
                  }`
                },
                'NUMBER'
              )
            "
            :icon="!isEmpty(row.journalEntry) ? getProviderIcon(row?.priceList?.[0]?.priceProvider?.type ?? '') : ''"
            :weight="500"
            :hover-show-pop="!!row.priceList?.length"
          >
            <PricePopover v-if="row.priceList?.length" :priceList="row.priceList" />
          </TextWithIconCell>
        </template>
      </el-table-column>

      <el-table-column
        :label="t('report.marketValue')"
        width="120"
        class-name="elv-valuation-record-table-row__cell-referenceNo"
      >
        <template #default="{ row }">
          <TextCell
            :text="
              formatNumberToSignificantDigits(
                row.marketValue,
                2,
                `${entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `}`
              )
            "
            :titleDesc="
              fieldValueFormat(
                row.marketValue,
                {
                  price: true,
                  keepPoint: true,
                  symbol: `${
                    entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `
                  }`
                },
                'NUMBER'
              )
            "
            align="right"
            :weight="500"
          />
        </template>
      </el-table-column>

      <el-table-column :label="t('valuation.crypto.unrealizedGainLoss')" width="160">
        <template #default="{ row }">
          <TextCell
            :text="
              formatNumberToSignificantDigits(
                row.unrealizedGainLoss,
                2,
                `${entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `}`
              )
            "
            :titleDesc="
              fieldValueFormat(
                row.unrealizedGainLoss,
                {
                  price: true,
                  keepPoint: true,
                  symbol: `${
                    entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `
                  }`
                },
                'NUMBER'
              )
            "
            align="right"
            :weight="500"
          />
        </template>
      </el-table-column>

      <el-table-column :label="t('valuation.crypto.journalNo')" width="130" fixed="right">
        <template #default="{ row }">
          <TextCell :text="row.journalActivity.journalNo" align="right" :weight="500" size="13px" />
        </template>
      </el-table-column>
    </el-table>
    <elv-pagination
      class="elv-valuation-record-list-pagination"
      size-popper-class="elv-valuation-record-pagination__sizes-popper"
      :limit="props.params.limit"
      :current-page="props.params.page"
      :total="props.tableData.total"
      @current-change="onChangePage"
      @size-change="onChangePageSize"
    />
  </div>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import { map, isEmpty } from 'lodash-es'
import utc from 'dayjs/plugin/utc'
import { useEntityStore } from '@/stores/modules/entity'
import PricePopover from '../../../components/PricePopover.vue'
import TextCell from '@/components/Base/Table/Cell/TextCell.vue'
import IconWithTextCell from '@/components/Base/Table/Cell/IconWithTitleCell.vue'
import VerticalTextCell from '@/components/Base/Table/Cell/VerticalTextCell.vue'
import TextWithIconCell from '@/components/Base/Table/Cell/TextWithIconCell.vue'
import { formatNumber, fieldValueFormat, formatNumberToSignificantDigits } from '@/lib/utils'

import coinGeckoIcon from '@/assets/img/project/coinGeckoIcon.png'
import defaultPriceIcon from '@/assets/icons/defaultPriceIcon.svg'
import exchangeRateIcon from '@/assets/img/project/exchangeRateIcon.png'

const props = defineProps({
  tableData: {
    type: Object,
    required: true
  },
  tableLoading: {
    type: Boolean,
    default: false
  },
  tableHeight: {
    type: String,
    required: true
  },
  params: {
    type: Object,
    required: true
  }
})

dayjs.extend(utc)
const { t } = useI18n()
const emit = defineEmits(['onChangePage', 'onChangePageSize'])
const entityStore = useEntityStore()
const { entityDetail } = storeToRefs(entityStore)

const tableListRef = useTemplateRef('tableListRef')

const getTypeName = (type: string) => {
  const typeMap: any = {
    ASSET: 'Asset',
    LIABILITY: 'Liability',
    EQUITY: 'Equity',
    REVENUE: 'Revenue',
    EXPENSE: 'Expense',
    OTHER: 'Other'
  }
  return typeMap[type]
}

const getProviderIcon = (type: string) => {
  switch (type) {
    case 'COINGECKO':
    case 'API_COINGECKO':
      return coinGeckoIcon
    case 'EXCHANGERATE':
    case 'API_EXCHANGE_RATE':
      return exchangeRateIcon
    default:
      return defaultPriceIcon
  }
}

const getAuxiliaryCodes = (row: any) => {
  return (
    map(row.auxiliaryValueList, (item: any) => {
      return item?.entityAccount?.name || item?.counterparty?.name || item?.auxiliaryItem?.value
    }) || []
  ).join('/')
}

const onChangePage = (page: number) => {
  emit('onChangePage', page)
}

const onChangePageSize = (limit: number) => {
  emit('onChangePageSize', limit)
}

defineExpose({
  ref: tableListRef
})
</script>

<style lang="scss"></style>
