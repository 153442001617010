<template>
  <div>
    <el-table
      ref="tableListRef"
      v-table-skeleton="{ loading: props.tableLoading, rows: 17 }"
      :data="props.tableData.list"
      border
      stripe
      :height="props.tableHeight"
      class="elv-valuation-record-table"
      header-cell-class-name="elv-valuation-record-table-header__cell"
      header-row-class-name="elv-valuation-record-table-header"
      row-class-name="elv-valuation-record-table-row"
      cell-class-name="elv-valuation-record-table-row__cell"
    >
      <el-table-column type="index" width="40" align="center" class-name="elv-valuation-record-table-row__cell-index">
        <template #default="{ row }">
          <span>{{ (row.rowIndex + 1) * props.params.page }}</span>
        </template>
      </el-table-column>
      <el-table-column :label="t('valuation.crypto.lotNo')" width="120">
        <template #default="{ row }">
          <TextCell :text="row.lotNo ?? ''" :weight="500" />
        </template>
      </el-table-column>
      <el-table-column :label="t('common.type')" width="100">
        <template #default="{ row }">
          <TextCell :text="getTypeName(row.type) ?? ''" font-family="Plus Jakarta Sans" />
        </template>
      </el-table-column>
      <el-table-column :label="t('common.date')" width="125">
        <template #default="{ row }">
          <TextCell :text="formatDatetime(row.datetime)" />
        </template>
      </el-table-column>
      <el-table-column :label="t('valuation.crypto.acquisitionNo')" width="120">
        <template #default="{ row }">
          <TextCell :text="row.acquisitionNo ?? '-'" />
        </template>
      </el-table-column>
      <el-table-column :label="t('report.Journal')" width="190">
        <template #default="{ row }">
          <VerticalTextCell
            :up-text="row.journalType?.name ?? '-'"
            up-font-family="Plus Jakarta Sans"
            :down-text="row.journalActivity?.journalNo ?? ''"
            align="left"
          />
        </template>
      </el-table-column>
      <el-table-column
        :label="t('valuation.crypto.referenceNo')"
        show-overflow-tooltip
        class-name="elv-valuation-record-table-row__cell-referenceNo"
        width="180"
      >
        <template #default="{ row }">
          <TextCell :text="row.creator !== 'IMPORT' ? row.journalActivity?.referenceNo : row?.referenceNo" />
        </template>
      </el-table-column>
      <el-table-column :label="t('common.currency')" width="150">
        <template #default="{ row }">
          <IconWithTextCell
            :icon="row.underlyingCurrency?.logo"
            :title="row.underlyingCurrency?.showSymbol"
            titleFontFamily="Barlow"
            :titleWeight="400"
          />
        </template>
      </el-table-column>

      <el-table-column
        :label="t('valuation.crypto.quantity')"
        width="110"
        class-name="elv-valuation-record-table-row__cell-referenceNo"
      >
        <template #default="{ row }">
          <TextCell
            :titleDesc="row.creator !== 'IMPORT' ? formatNumber(row.acquisitionQuantity, 20) : ''"
            :text="row.creator !== 'IMPORT' ? formatNumber(row.acquisitionQuantity, 20) : '-'"
            :weight="500"
            align="right"
          />
        </template>
      </el-table-column>

      <el-table-column :label="t('valuation.crypto.acquisitionPrice')" width="120">
        <template #default="{ row }">
          <TextWithIconCell
            :text="
              formatNumberToSignificantDigits(
                row.acquisitionPrice,
                2,
                `${entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `}`
              )
            "
            :titleDesc="
              fieldValueFormat(
                row.acquisitionPrice,
                {
                  price: true,
                  keepPoint: true,
                  symbol: `${
                    entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `
                  }`
                },
                'NUMBER'
              )
            "
            :icon="''"
            :hover-show-pop="false"
            :weight="500"
          >
          </TextWithIconCell>
        </template>
      </el-table-column>

      <el-table-column :label="t('valuation.crypto.costBasis')" width="110">
        <template #default="{ row }">
          <TextCell
            :text="
              row.creator !== 'IMPORT'
                ? formatNumberToSignificantDigits(
                    row.acquisitionCostBasis,
                    2,
                    `${entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `}`
                  )
                : '-'
            "
            :titleDesc="
              fieldValueFormat(
                row.acquisitionCostBasis,
                {
                  price: true,
                  keepPoint: true,
                  symbol: `${
                    entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `
                  }`
                },
                'NUMBER'
              )
            "
            align="right"
            :weight="500"
          />
        </template>
      </el-table-column>

      <el-table-column
        v-if="entityStore.entityDetail?.fairValueCostBasisMethodology === 'COST_AVERAGE'"
        :label="t('valuation.crypto.averageCostPrice')"
        width="135"
      >
        <template #default="{ row }">
          <TextCell
            :text="
              row.averagedCostPrice !== null
                ? formatNumberToSignificantDigits(
                    row.averagedCostPrice,
                    2,
                    `${entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `}`
                  )
                : '-'
            "
            :titleDesc="
              fieldValueFormat(
                row.averagedCostPrice ?? 0,
                {
                  price: true,
                  keepPoint: true,
                  symbol: `${
                    entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `
                  }`
                },
                'NUMBER'
              )
            "
            align="right"
            :weight="500"
          />
        </template>
      </el-table-column>

      <el-table-column :label="t('valuation.crypto.remainQuantity')" width="140" fixed="right">
        <template #default="{ row }">
          <ProgressCell :text="formatNumber(row.remainQuantity, 20)" :percentage="getPercentage(row)" />
        </template>
      </el-table-column>
    </el-table>
    <elv-pagination
      class="elv-valuation-record-list-pagination"
      size-popper-class="elv-valuation-record-pagination__sizes-popper"
      :limit="props.params.limit"
      :current-page="props.params.page"
      :total="props.tableData.total"
      @current-change="onChangePage"
      @size-change="onChangePageSize"
    />
  </div>
</template>

<script setup lang="ts">
import Big from 'big.js'
import dayjs from 'dayjs'
import { useI18n } from 'vue-i18n'
import { useEntityStore } from '@/stores/modules/entity'
import TextCell from '@/components/Base/Table/Cell/TextCell.vue'
import ProgressCell from '@/components/Base/Table/Cell/ProgressCell.vue'
import VerticalTextCell from '@/components/Base/Table/Cell/VerticalTextCell.vue'
import TextWithIconCell from '@/components/Base/Table/Cell/TextWithIconCell.vue'
import IconWithTextCell from '@/components/Base/Table/Cell/IconWithTitleCell.vue'
import { formatNumberToSignificantDigits, fieldValueFormat, formatNumber } from '@/lib/utils'

const props = defineProps({
  tableData: {
    type: Object,
    required: true
  },
  tableLoading: {
    type: Boolean,
    default: false
  },
  tableHeight: {
    type: String,
    required: true
  },
  params: {
    type: Object,
    required: true
  }
})

const { t } = useI18n()
const entityStore = useEntityStore()
const { entityDetail } = storeToRefs(entityStore)
const emit = defineEmits(['onChangePage', 'onChangePageSize'])

const tableListRef = useTemplateRef('tableListRef')

const formatDatetime = (datetime: string) => {
  return dayjs(datetime)
    .tz(entityStore.entityDetail?.timezone ?? 'UTC')
    .format('YYYY/MM/DD HH:mm')
}

const getTypeName = (type: string) => {
  const typeMap: any = {
    ASSET: 'Asset',
    LIABILITY: 'Liability',
    EQUITY: 'Equity',
    REVENUE: 'Revenue',
    EXPENSE: 'Expense',
    OTHER: 'Other'
  }
  return typeMap[type]
}

const getPercentage = (row: any) => {
  if (Big(row.acquisitionQuantity).eq(0) && Big(row.remainQuantity).eq(0)) return 0
  if (Big(row.acquisitionQuantity).eq(0)) return 0
  return Big(row.remainQuantity).div(row.acquisitionQuantity).times(100).toNumber()
}

const onChangePage = (page: number) => {
  emit('onChangePage', page)
}

const onChangePageSize = (limit: number) => {
  emit('onChangePageSize', limit)
}

defineExpose({
  ref: tableListRef
})
</script>

<style lang="scss"></style>
